@import "variables.scss";

.App {
    width: inherit;
    display: flex;
    font-family: 'Be Vietnam', sans-serif;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}